
   
@font-face {
  font-family: 'Bergen Sans Semi Bold';
  src: url('./assets/Fonts/Bergen-Sans/BergenSans-SemiBold.otf') format('opentype');
}
@font-face {
  font-family: 'GT Eesti Text Regular';
  src: url('./assets/Fonts/GT-Estie/GT-Eesti-Text-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'GT Eesti Text Medium';
  src: url('./assets/Fonts/GT-Estie/GT-Eesti-Text-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'GT Eesti Text Bold';
  src: url('./assets/Fonts/GT-Estie/GT-Eesti-Text-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'GT Eesti Text Light';
  src: url('./assets/Fonts/GT-Estie/GT-Eesti-Text-Light.ttf') format('truetype');
}


body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
